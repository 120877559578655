import React, { useEffect } from "react";

// import Custom Components
import Breadcrumb from "../components/common/breadcrumb";
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/common/page-header";
import Seo from "../components/common/SEO";

function AboutOne() {
  useEffect(() => {
    document.querySelector(".footer-middle") &&
      document.querySelector(".footer-middle").classList.add("border-0");
  }, []);

  return (
    <MainLayout>
      <div className="main">
        <Seo title="Owls - Privacy and Policy Page" />
        <h1 className="d-none">
          Owls - Privacy and Policy
        </h1>

        <PageHeader title="Privacy and Policy" />

        {/* <h1 className="d-none">Molla React Ecommerce - </h1> */}

        <Breadcrumb
          title="Privacy and Policy"
          //   parent1={[ "/wholesale"]}
          adClass="border-0 mb-0"
        />

        <div className="page-content pb-0">
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <h2 className="title">Our Vision</h2>
                <p>
                  We have partnered with Mohawk, the largest flooring company in
                  the USA, to offer the widest possible selection at reasonable,
                  discounted prices. With this deal in place, we can offer the
                  broadest selection of inventory to homeowners all over the
                  nation, with extremely low cost but incredibly high quality.
                  Our goal is to make home remodeling affordable for everyone by
                  bringing you the largest selection of home improvement goods
                  at insanely discounted prices!{" "}
                </p>
              </div>

              <div className="col-lg-6">
                <h2 className="title">Our Mission</h2>
                <p>
                  It doesn’t matter if you’re a homeowner, house flipper, or
                  reseller, you’ll be astonished to see the number of products
                  we have in stock and the record low prices attached to them.
                  Offering 30%-80% off prices compared to other average
                  retailers, as well as our impressive stock of appliances,
                  tools, flooring, outdoor furniture, and everything else in
                  between! Not to mention, our inventory is expanding daily!
                  Remodeling your home has never been easier or more affordable.
                  What are you waiting for? Come visit Jimenez today, and snag
                  some hot deals!{" "}
                </p>
              </div>
            </div>

            <div className="mb-5"></div>
          </div> */}

          <div className="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb-3 mb-lg-0">
                  <p className="lead  mb-3">
                    We respect your privacy and the security of your personal
                    information. This Privacy Policy outlines how JS Products
                    and its subsidiaries and affiliates ( collectively, "JSP"
                    and occasionally referred to "us" or "we") manage the
                    collection, use, and sharing of data across this website
                    ("Site"), from emails, text messages, and other electronic
                    messages between you and us, or through mobile or desktop
                    applications related to this Site. Our Privacy Policy was
                    last updated on August 26, 2021.
                  </p>
                  <ol type="1" style={{ listStyle: "decimal" }}>
                    <li>
                      {" "}
                      <h4 className="subtitle">
                        {" "}
                        Data Collection and Use:
                      </h4>{" "}
                      <p className="mb-2">
                        This Section describes the information we collect when
                        you use our Site and how we make use of that
                        information, including what information is shared with
                        third parties. Some of the information is Personally
                        Identifiable Information ("PII"). When we use the term
                        "PII", we mean information that identifies, relates to,
                        describes, is reasonably capable of being associated
                        with, or could reasonably be linked, directly or
                        indirectly, with a particular consumer or household. PII
                        does not include:
                      </p>
                    </li>
                    <ul type="1" style={{ listStyle: "circle" }}>
                      <li>
                        {" "}
                        <p className="mb-2">
                          Publicly available information from government
                          records.
                        </p>
                      </li>
                      <li>
                        {" "}
                        <p className="mb-2">
                          Deidentified or aggregated consumer information.
                        </p>
                      </li>
                      <li>
                        {" "}
                        <p className="mb-2">
                          Information excluded from the CCPA’s (defined below)
                          scope, like:
                        </p>
                      </li>
                      <li>
                        {" "}
                        <p className="mb-2">
                          health or medical information covered by the Health
                          Insurance Portability and Accountability Act of 1996
                          (HIPAA) and the California Confidentiality of Medical
                          Information Act (CMIA) or clinical trial data; or
                        </p>
                      </li>
                      <li>
                        {" "}
                        <p className="mb-2">
                          personal information covered by certain
                          sector-specific privacy laws, including the Fair
                          Credit Reporting Act (FRCA), the Gramm-Leach-Bliley
                          Act (GLBA) or California Financial Information Privacy
                          Act (FIPA), and the Driver’s Privacy Protection Act of
                          1994.
                        </p>
                      </li>
                    </ul>
                    <h6 className="subtitle">
                      {" "}
                      <b> Automatically-Collected Data:</b>
                      <br />
                    </h6>{" "}
                    <p className="mb-2">
                      PII is collected from all visitors to our Site. This data
                      includes geo-location, browser version, device, session
                      information, and more. We use this analytical information
                      to track metrics of our Site, diagnose problems with our
                      servers, administer our Site, and to make improvements to
                      our Site. This data is collected by Google Analytics using
                      cookies and web beacons. You can read more about how our
                      Site uses cookies and web beacons below in Section 5
                      Technologies Used.
                    </p>
                    <h6 className="subtitle">
                      {" "}
                      <b> Site Purchases:</b>
                      <br />
                    </h6>{" "}
                    <p className="mb-2">
                      To make a purchase on our Site, we need PII including your
                      full name, email address, phone number, shipping & billing
                      addresses, and credit card details. This information is
                      used to confirm, process, record, and ship your order as
                      well as send you communications regarding your order such
                      as order confirmations, tracking information, and other
                      order updates. Your IP address is also collected, which is
                      used for fraud prevention.
                      <br />
                      Your shipping address is provided to third party service
                      providers (UPS and TaxJar) to help show shipping options
                      and to calculate state sales tax. Please refer to those
                      service provider’s privacy policies on how they manage
                      your data. Your credit card information is used to bill
                      you for our products. By making a purchase on our Site,
                      you consent to us providing your credit card information
                      to third party service providers to process the
                      transaction. We use EBizCharge for online credit card
                      authorization and processing. We do not retain your card
                      information after the completion of your order. Please
                      refer to EBizCharge's Privacy Policy for additional
                      information on their use of your billing information. If
                      you complete your purchase using Amazon Pay, please refer
                      to Amazon Pay's Customer Agreement. If you complete your
                      purchase using PayPal, please refer to Paypal's Privacy
                      Policy. In order to process and record orders, PII is
                      securely stored and transferred between our website
                      database, Enterprise Resource Planning (ERP) system, and
                      Warehouse Management System (WMS). If you are a California
                      consumer, you have the right to request more information
                      about these systems by Contacting Us.
                    </p>
                    <h6 className="subtitle">
                      {" "}
                      <b> Site Accounts:</b>
                      <br />
                    </h6>{" "}
                    <p className="mb-2">
                      When you create an account on our Site, we request PII
                      including your full name, email address, and to create a
                      password. Once you create an account, you can add your
                      shipping address and billing address to make checkout
                      faster. This information is securely stored in our Site
                      backend database. We use account information to customize
                      Site experiences, track purchase history, and improve your
                      experience on our Site. If you would like to delete your
                      account, please contact us.
                    </p>
                    <h6 className="subtitle">
                      {" "}
                      <b> Contacting Support:</b>
                      <br />
                    </h6>{" "}
                    <p className="mb-2">
                      When you submit a request to us on our Site via a "Contact
                      Us" page, use our chat widget, email our support address,
                      or call our support phone number, we need PII such as your
                      name, phone number, and email address. Additionally, we
                      may need other information such as contact reason,
                      attachments, and message. This information is used to
                      answer and resolve your request. If you require a
                      replacement product or part(s), we will need your shipping
                      address. If you call our support number, we may record the
                      audio of the call for reference and training purposes.
                      Sensitive information is automatically edited out of
                      recordings after calls are completed. We manage all
                      support requests through our third party ticket management
                      tool, Zendesk. Please refer to Zendesk's Privacy Policy.
                    </p>
                    <h6 className="subtitle">
                      {" "}
                      <b> Giveaways and Contests:</b>
                      <br />
                    </h6>{" "}
                    <p className="mb-2">
                      Occasionally, our Site will collect PII to run contests.
                      If you participate in a contest, we require you to provide
                      us with your name, email, and phone number. If the contest
                      prize is a product, the winner(s) will need to provide
                      their shipping address. This information is used to
                      administer contests, contact winners, send out giveaways,
                      and for future marketing campaigns. Your participation is
                      not required and, if you sign up using your email, you can
                      unsubscribe at any time using the link at the bottom of
                      the email or by contacting us.
                    </p>{" "}
                    <h6 className="subtitle">
                      {" "}
                      <b> Social Media:</b>
                      <br />
                    </h6>{" "}
                    <p className="mb-2">
                      We interact with our users on social media sites such as
                      Facebook, Twitter, and Instagram. If you make a post or
                      contact us using a social media platform, we respond using
                      third party social media management tools like Sprout
                      Social and Zendesk. Please refer to the specific social
                      media privacy policy as well as our third party tool’s
                      privacy policies to learn how your data is managed when
                      interacting with us through these platforms.
                    </p>
                    <h6 className="subtitle">
                      {" "}
                      <b> Access, Correction, and Deletion of your Data:</b>
                      <br />
                    </h6>{" "}
                    <p className="mb-2">
                      You have the right to review and change any PII and to
                      request that we delete your information. To make such a
                      request, you will need to contact us here and verify your
                      identity. If you have an account on our Site, you can
                      access, update, and delete your information by logging
                      into your account. If you are subscribed to receive
                      marketing emails from us, you can unsubscribe any time
                      using the link at the bottom of the emails or by
                      contacting us. Please be aware that we may not accommodate
                      a request to change information if we believe the change
                      would violate any law or legal requirement or cause the
                      information to be incorrect.
                    </p>
                    <h6 className="subtitle">
                      {" "}
                      <b> Marketing:</b>
                      <br />
                    </h6>{" "}
                    <p className="mb-2">
                      We use MailChimp, a third party service provider, to
                      collect user emails and send marketing emails. If you
                      provide us your email address, we will only use it to send
                      you marketing and promotional emails if you opted-in. You
                      can unsubscribe from these emails at any time using the
                      link at the bottom of the email or by contacting us. We
                      also use PII to personalize your website experience and to
                      deliver content and product and service offerings relevant
                      to your interests, including targeted offers and ads
                      through our Site.
                    </p>
                    <li>
                      {" "}
                      <h4 className="subtitle">
                        {" "}
                        California Consumers Rights:
                      </h4>{" "}
                      <p className="mb-2">
                        California residents have specific rights on how we
                        handle their PII. The information in this Section 2
                        (California Consumers Rights) applies to all users of
                        our Site but the additional rights apply solely to
                        visitors, users, and others who reside in the State of
                        California, and do not apply to employment-related PII
                        collected from California-based employees, job
                        applicants, contractors, or similar individuals.
                        Additionally, where indicated, this Section 2
                        (California Consumers Rights) does not apply to PII
                        reflecting a written or verbal business-to-business
                        communication ("B2B personal information"). You can
                        learn more about California consumer rights and the
                        California Consumer Privacy Act (CCPA) on California's
                        OAG website. Any terms defined in the CCPA have the same
                        meaning when used in this Section 2 (California
                        Consumers Rights).
                        <br />
                        Categories of User Data, Sources, Purposes and
                        Disclosure:
                        <br />
                        The list below details categories of PII data collected
                        and disclosed for a business purpose, sources of PII,
                        and the business purposes PII data was used in the last
                        12 months. More information about these can be found
                        above under Section 1. Data Collection and Use.
                      </p>
                      <b>
                        Categories of PII Collected and Disclosed for a Business
                        Purpose in the Last 12 Months
                      </b>
                      <br />
                      <ul type="1" style={{ listStyle: "circle" }}>
                        <li>
                          {" "}
                          Identifiers (e.g. name, ship-to and bill-to addresses,
                          email address, phone number for processing orders and
                          support requests)
                        </li>
                        <li>
                          Personal information categories listed in the
                          California Customer Records statute (Cal. Civ. Code §
                          1798.80(e)) (e.g. name, address, telephone number for
                          processing orders and support requests)
                        </li>
                        <li>
                          Commercial information (e.g. products purchased and
                          purchase history for processing orders)
                        </li>
                        <li>
                          Internet and network activity (e.g. search history for
                          marketing proposes)
                        </li>
                        <li>
                          Geolocation information (e.g. device location and IP
                          address for analytical and marketing purposes)
                        </li>
                        <li>
                          Sensory Data (e.g., audio recordings for support phone
                          calls)
                        </li>
                        <li>
                          Payment information (collected by our third party
                          payment processors for processing orders)
                        </li>
                      </ul>
                      <b>Examples of Sources from Which PII is Collected</b>
                      <br />
                      <ul type="1" style={{ listStyle: "circle" }}>
                        <li> Direct from our Site (e.g. making purchases)</li>
                        <li>Site account usage (e.g. creating an account)</li>
                        <li>Passive collection (e.g. browsing the Site)</li>
                        <li>
                          Communicating with us (e.g. contacting our support
                          team)
                        </li>
                        <li>
                          Email sign ups (e.g. newsletter subscribe forms and
                          giveaways)
                        </li>
                      </ul>
                      <b>
                        Examples of Business Purposes for Which PII Was
                        Collected in the Last 12 Months
                      </b>
                      <br />
                      <ul type="1" style={{ listStyle: "circle" }}>
                        <li> Processing Site orders</li>
                        <li>Analyzing Site usage</li>
                        <li>Maintaining and improving the Site</li>
                        <li>Communicating and responding to support issues</li>
                        <li>Administering giveaways</li>
                        <li>
                          Sending marketing, advertising and promotional
                          messages
                        </li>
                        <li>Complying with legal requirements</li>
                        <li>Fraud detection and prevention</li>{" "}
                        <li>Protecting us, our users and the general public</li>
                      </ul>
                      <h6 className="subtitle">
                        {" "}
                        <b> Who We Disclose PII To:</b>
                        <br />
                      </h6>{" "}
                      <p className="mb-2">
                        We share PII with service providers, affiliates, and
                        subsidiaries, in addition to the specific parties
                        described elsewhere in this Privacy Policy.
                      </p>
                      <h6 className="subtitle">
                        {" "}
                        <b>Data Request Rights:</b>
                        <br />
                      </h6>{" "}
                      <p className="mb-2">
                        As a California consumer, you have the right to make
                        verifiable access and deletion requests no more than
                        twice in a 12-month period after providing a verifiable
                        consumer request. A verifiable consumer request can be
                        made using the contact methods provided at in the
                        "Contact Us" section at the bottom of the page. You may
                        also authorize someone to make a verifiable consumer
                        request on your behalf or you can make a verifiable
                        consumer request on behalf of your minor child. It is
                        important that your request provide sufficient
                        information to allow us to reasonably identify you or
                        the person about whom we collected PII and describes
                        your request with sufficient detail so that we can
                        respond to it. We cannot respond to requests that do not
                        provide sufficient information.<br/>
                        We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 45 days unless the complexity and volume of requests warrant more under applicable law), we will inform you of the reason and extension period in writing.
<br/>Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your PII that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.
We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.

                      </p>
                      <h6 className="subtitle">
                        {" "}
                        <b>Data Access Requests:</b>
                        <br />
                      </h6>{" "}
                      <p className="mb-2">You have the right to know the categories of PII we have collected about you in the last 12 months, the categories of sources from which PII is collected, the business purposes for collecting PII data, the specific PII data we collected about you (also called a data portability request), and the categories of PII data disclosed for business purposes. To submit a data access request, please use one of the contact methods provided at in the "Contact Us" section at the bottom of the page and let us know you want to make a "California Consumer Data Access Request". We do not provide these access or portability rights for B2B personal information</p>
                      <h6 className="subtitle">
                        {" "}
                        <b>Data Deletion Requests:</b>
                        <br />
                      </h6>{" "}
                      <p className="mb-2">
                      You have the right to request that we delete your PII subject to certain exceptions. To submit a data access request, please use one of the contact methods provided at in the "Contact Us" section at the bottom of the page and let us know you want to make a "California Consumer Data Deletion Request".
<br/>We may deny your deletion request if we are unable to verify your identity after making all reasonable efforts or if any of the following apply:
<br/>
<ol type="1" style={{ listStyle: "decimal" }}>
<li>
Complete the transaction for which we collected the PII, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you.

</li>
<li>
Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.

</li>
<li>
Debug products to identify and repair errors that impair existing intended functionality.
</li>
<li>
Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.
</li>
<li>  Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
<li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent.</li>
<li>
Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.
</li>
<li>  Comply with a legal obligation.</li>
<li>
Make other internal and lawful uses of that information that are compatible with the context in which you provided it.
</li>
</ol>

                      </p>
                      <h6 className="subtitle">
                        {" "}
                        <b>Selling Data:</b>
                        <br />
                      </h6>{" "}
                      <p className="mb-2">
                      
We have not sold PII and currently have no plans to sell PII. To opt-out of any potential future selling of data, please email support@rakdistribution.net with the subject line "California Consumer Selling Data Request".

                      </p>
                      <h6 className="subtitle">
                        {" "}
                        <b>Non-Discrimination:</b>
                        <br />
                      </h6>{" "}
                      <p className="mb-2">We will not discriminate against you for exercising your CCPA rights. We will not deny you goods or services; charge you different prices or rates for goods or services, including through granting discounts or other benefits or imposing penalties; provide you a different level or quality of goods or services; or suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services. We may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your PII’s value and contain written terms that describe the program’s material aspects. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time. We currently provide financial incentives through our giveaways and contests described in Section 1. Data Collection and Use.</p>
                    </li>
                    <li>
                      <h4> Nevada Consumers Rights</h4>
                      <p className="mb-2">
                      Nevada residents have the right to request their PII is not sold, even if the information is currently not being sold. We have not sold PII and currently have no plans to sell PII. To make a request to opt-out of potential future selling of data, please send an email to support@rakdistribution.net with the subject line "Nevada Consumer Data Request".
                      </p>
                    </li>
                    <li>
                      <h4> Sharing Information with Third Parties</h4>
                      <p className="mb-2">
                      We may use third parties and affiliates to help us operate our business and our Site or administer activities and marketing on our behalf, such as payment processing, email marketing, product and order support, and online advertising. We may share your information with these third parties for those limited purposes and more details about the third parties can be found in our Privacy Policy above under Section 1. Data Collection and Use. Additionally, third parties such as advertisers, ad networks and servers, content providers, and application providers may use cookies or other tracking technologies to collect information about you when you use our Site. We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
<br/>Your PII may be passed on to a third party to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding. We may also disclose specific information when we determine that such disclosure is necessary to comply with applicable law or to protect the interests or safety of JSP or other visitors to our Site.
<br/>For legal reasons, we will share information if we believe that it is necessary to respond to law enforcement or government requests as required by applicable law, court order, or governmental regulations; investigate potential violations of and enforce our Terms of Use; detect, prevent, respond to fraud and security issues; and to protect against harm to our rights, property, or safety of our users or the public as required or permitted by law.
                      </p>
                    </li>
                    <li>
                      <h4 className="subtitle">  Technologies Used</h4>
                      <p className="mb-2">  
                      The Site uses cookies, flash cookies, and web beacons to keep track of your purchases and other activity on our Site in order to enhance your experience on our Site.
<br/>A "cookie" is a small text file transferred by a website to your device. Accepting the cookies used on our Site does not give us access to your PII, but we may use cookies to identify your device. Cookies are typically classified as either "session" cookies or "permanent" cookies.
<br/>"Session" cookies do not stay on your device after you leave our website or close your browser. The aggregate information collected allows us to analyze traffic patterns on our Site. This enables us over time to provide a better experience on our Site by improving content and making our Site easier to use.
<br/>"Permanent" cookies are those that remain on your device after you leave our Site. They are used to facilitate shopping, personalization, and account registration. For example, cookies keep track of items in your cart as you continue to shop and allow you to enter your password only once on web pages where a login is required. "Permanent" cookies can be manually removed by the user.
<br/>Most browsers automatically accept cookies by default, but you can usually refuse cookies or selectively accept certain cookies by adjusting the preferences in your browser. If you turn off cookies, there may be some features of our Site that will not be available to you and some web pages may not display properly. Read how to manage cookies in Google Chrome and how to manage cookies in Firefox.
<br/>JSP makes no representations or warranties about the accuracy of the information contained in the above websites, which are provided for reference only. Each user accepts all responsibility for accessing these third-party websites and assumes all risks.
<br/>A "web beacon" is a small electronic file on our Site or in our emails that allow us to count users who have visited our Site or opened an email as well as other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).

                      </p>
                    </li>
                    <li>
                      <h4 className="subtitle">   Marketing and Advertising Choices</h4>
                      <p className="mb-2">  
                      <b>Email Marketing:</b><br/>
By signing up for our marketing emails or making a purchase on our Site, you opt-in for us to send you various marketing emails. You can opt-out of these emails at any time using the link at the bottom of the emails or by contacting us using one of the methods provided at in the "Contact Us" section at the bottom of the page.

                      </p>
                      <p className="mb-2">  
                      <b>Text Messages:</b><br/>
                      To the extent you are receiving SMS messaging from us, you may opt out of receiving SMS messaging by replying to any message we send you with the word "STOP" or contacting us using one of the methods provided at in the "Contact Us" section at the bottom of the page. To the extent you receive push notifications from our Progressive Web App (PWA) Site, you may opt-out of them for your device through your account and device settings.
                      </p>
                      <p className="mb-2">  
                      <b>Interest-Based Advertising:</b><br/>
                      
We use several third party services to participate in Interest-Based Advertising (IBA). This allows us to tailor our display-ads to your individual interests based on your online shopping and browsing behavior. Our third party advertising providers also provide us with additional advertising related services like ad delivery, reporting, attribution, analytics, and market research. You can opt-out of our advertising by visiting the National Advertising Institute (NAI) opt-out page, Criteo’s Privacy Policy, and Google’s opt-out page.
                      </p>
                      <p className="mb-2">  
                      <b>Opt-Out Note:</b><br/>
                      Please allow up to ten (10) business days for us and our service providers to process your request. If you make a purchase through our Site, you may be opted back in to our marketing communications until you opt out or unsubscribe once again. We may continue to send you transactional, support, or operational communications even if you opt-out.
                      </p>
                    </li>
                    <li>
                      <h4 className="subtitle"> Security of Information</h4>
                      <p className="mb-2"> 
                      We use procedural and technological security measures, which are reasonably designed to help protect PII from unauthorized access or disclosure, consistent with industry standards. When you place an order or submit information to us via forms, the data is encrypted using industry standard Secure Socket Layer (SSL) security technology.
We use encryption, passwords, and physical security measures to help protect your PII against unauthorized access and disclosure. However, it is always possible that third parties will unlawfully intercept or access transmissions or defeat these measures. Therefore, we do not promise and cannot guarantee (and thus you should not expect) that PII will never be viewed or used by others. While we are committed to protecting your information, we cannot ensure or warrant the security of any information you transmit to us.

                       </p>
                    </li>
                    <li>
                      <h4 className="subtitle">  Links to Other Sites</h4>
                      <p className="mb-2">The Site may contain links to other websites. We are not responsible for the privacy practices or the content of such web sites or for the privacy policies and practices of third parties.</p>

                    </li>
                    <li >
                      <h4 className="subtitle">   Policy Toward Children</h4>
                      <p className="mb-2">Our Site is not targeted or intended for children under 16 years of age. No one under age 16 may provide any PII to or on our Site. We do not knowingly collect PII from or about children under age 16. If we are notified that we have collected PII from such a child, we will delete all information as soon as possible.</p>

                    </li>
                    <li>
                      <h4 className="subtitle">    Privacy Policy Changes</h4>
                      <p className="mb-2">We reserve the right to make changes to this Privacy Policy. When we update our privacy policy, notice will be provided in a pop up banner when you visit the Website after any change. Your continued use of our Site after any changes have been made will constitute acceptance of such changes.</p>   </li>
                   <li>
                     <h4 className="subtitle">   Questions?</h4>
                     <p className="mb-2">
                     If you have any questions or concerns about our Privacy Policy, please contact us using one of the methods provided at in the "Contact Us" section at the bottom of the page.
                     </p>
                     {/* <p className="mb-2">
                    <b> CONTACT US</b><br/>
Please contact us with any questions or concerns. Our customer service department is available from 7 AM until 5 PM PST on weekdays. If you call outside these hours and leave a message, we will return your call. You can also contact us or try one of the methods listed below.
             <br/>      
6445 Montessouri St<br/>
Las Vegas, NV 89113<br/>
888-512-4452<br/>
support@rakdistribution.net<br/>

                     </p> */}
                   </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2"></div>

          {/* <div className="about-testimonials bg-light-2 pt-6 pb-6">
            <div className="container">
              <h2 className="title text-center mb-3">
                What Customer Say About Us
              </h2>
            </div>
          </div> */}
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(AboutOne);
